import { useQuery, useQueryClient } from "@tanstack/vue-query";
import CompanyApiRequests from "@/services/companyRequests";

const QUERY_KEYS = {
  companyState: ["companyState"],
};

export const useCompanyStateManagement = () => {
  const queryClient = useQueryClient();

  return {
    prefetchCompanyState: async () =>
      await queryClient.prefetchQuery({
        queryKey: QUERY_KEYS.companyState,
        queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
        staleTime: 600000, // 10 minutes
      }),
    invalidateCompanyState: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.companyState,
      });
    },
    // Synchronous check for feature flag based on cached data.
    // Use this when the flag is unlikely to change.
    // If the cache is empty, return 'false' (assuming the flag is disabled by default).
    FetchAndVerifyFlag: (featureName) => {
      const companyStateCache = queryClient.getQueryData(
        QUERY_KEYS.companyState,
      );
      if (companyStateCache) {
        return companyStateCache.featureFlags.includes(featureName);
      }
      return false;
    },
    // Asynchronous check for feature flag, ensuring up-to-date data from the server.
    // Use this for critical features or flags that may change.
    // It fetches fresh data if not cached, ensuring real-time accuracy.
    AsyncFetchAndVerifyFlag: async (featureName) => {
      try {
        const companyStateCache = await queryClient.ensureQueryData({
          queryKey: QUERY_KEYS.companyState,
          queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
          onError: () => false,
        });
        if (companyStateCache) {
          return companyStateCache.featureFlags.includes(featureName);
        }
      } catch {
        return false;
      }
      return false;
    },
  };
};

export const useQueryCompanyState = () => {
  return useQuery({
    queryKey: QUERY_KEYS.companyState,
    queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
    staleTime: 600000, // 10 minutes
    refetchOnMount: true,
  });
};
