<template>
  <SearchBar
    placeholder="Search tracking number"
    :initial-search-term="filterQuery.tracking_number"
    @on-submit="changeTrackingNumberQuery($event)"
  />
  <Button
    class="p-button header-btn"
    data-cy="create-action-link"
    @click="emit('refresh-shipments')"
  >
    <i class="pi pi-refresh" />
    Refresh
  </Button>
  <Button
    v-if="displayCsvUpload"
    class="p-button header-btn"
    data-cy="toggle-csv-import"
    aria-controls="overlay_menu"
    @click="toggleCsvImportMenu"
  >
    <i class="pi pi-plus" />
    Import Shipments
  </Button>
  <div v-if="subMenuItems.length" data-cy="more-options-menu">
    <Menu
      id="overlay_menu"
      ref="menu"
      class="overlay-menu"
      :model="subMenuItems"
      :popup="true"
    />
  </div>
</template>
<script setup>
import SearchBar from "@/components/SearchBar";
import Menu from "primevue/menu";
import Button from "primevue/button";
import { ref, toRefs } from "vue";
import { UrlQuery } from "@/services/utils/urlQuery";
const { updateQueryParam, removeQueryParam } = UrlQuery();

const menu = ref();
const toggleCsvImportMenu = (event) => {
  menu.value.toggle(event);
};

const props = defineProps({
  displayCsvUpload: {
    type: Boolean,
    default: false,
  },
  subMenuItems: {
    type: Array,
    default: () => [],
  },
  filterQuery: {
    type: Object,
    default: () => {},
  },
});

const { filterQuery, displayCsvUpload } = toRefs(props);
const emit = defineEmits(["refresh-shipments"]);

const changeTrackingNumberQuery = (searchTerm) => {
  const key = "tracking_number";
  if (!searchTerm) {
    removeQueryParam(filterQuery.value, key);
  } else {
    updateQueryParam(filterQuery.value, key, searchTerm);
  }
};
</script>
