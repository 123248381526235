import { useCompanyStateManagement } from "@/queries";

export async function isPortalPreviewEnabled() {
  const { AsyncFetchAndVerifyFlag } = useCompanyStateManagement();
  return await AsyncFetchAndVerifyFlag("scout:flag_can_preview_portal");
}

export async function isBrandsEnabled() {
  const { AsyncFetchAndVerifyFlag } = useCompanyStateManagement();
  return await AsyncFetchAndVerifyFlag("scout:flag_rollout_can_view_brands");
}

export function isCsvImportEnabled() {
  const { FetchAndVerifyFlag } = useCompanyStateManagement();
  return FetchAndVerifyFlag("allow_csv_shipment_import");
}
